import choices from 'choices.js';
import multiSelect from 'choices.js';
import LazyLoad from 'vanilla-lazyload';

const productFilterForm = document.querySelectorAll('.product-cate-filter-form');
const tagWrapper = document.querySelector('.product-cate-filter-form__tags');
let storeMultipleValue = [];
let storeTypeMultipleValue = [];

const selectFields = document.querySelectorAll('.input .custom-select.form-control');
const multiSelectWrapper = document.querySelector('.form-select');

// single Select
if (selectFields) {
  selectFields.forEach(($selectField) => {
    const simpleSelect = new choices($selectField, {
      searchEnabled: false,
    });
  });
}

if (multiSelectWrapper) {
  const choices = new multiSelect(multiSelectWrapper, {
    removeItemButton: true,
  });

  setTimeout(() => {
    const customSelect = document.querySelector('.choices[data-type="select-multiple"]');
    if (customSelect) {
      customSelect.addEventListener('click', () => {
        if (customSelect.classList.contains('choices--open')) {
          choices.hideDropdown();
          customSelect.classList.remove('choices--open');
        } else {
          customSelect.classList.add('choices--open');
        }
      });

      document.addEventListener('click', (e) => {
        if (!document.querySelector('.choices[data-type="select-multiple"]').contains(e.target)) {
          if (customSelect) {
            customSelect.classList.remove('choices--open');
          }
        }
      });
    }
  }, 200);

  function addItemTriggerEvent() {
    if (document.getElementById('filterManuFacUid')) {
      let selectedNewBoxContent = document.getElementById('filterManuFacUid').value;
      const splitString = selectedNewBoxContent.split(",");
      let newClass = choices.dropdown.element;
      let choicesList = newClass.querySelectorAll('.choices__list--dropdown .choices__list .choices__item--selectable');
      splitString.forEach(($element) => {
        choicesList.forEach(($elementNew) => {
          if($element === $elementNew.getAttribute('data-value'))
          {
            choices.setChoiceByValue($elementNew.getAttribute('data-value'));
          }
        });
      });
    }
  }

  function addItemCatTriggerEvent() {
    if (document.getElementById('filterLoadCatUid'))
    {
      let selectedCatBoxContent = document.getElementById('filterLoadCatUid').value;
      const splitCatString = selectedCatBoxContent.split(",");
      let newCatClass = choices.dropdown.element;
      let choicesCatList = newCatClass.querySelectorAll('.choices__list--dropdown .choices__list .choices__item--selectable');
  
      splitCatString.forEach(($element) => {
        choicesCatList.forEach(($elementCat) => {
          if($element === $elementCat.getAttribute('data-value'))
          {
            choices.setChoiceByValue($elementCat.getAttribute('data-value'));
          }
        });
      });
    }
  }
  
  window.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(() => {
      addItemTriggerEvent();
      addItemCatTriggerEvent();
    }, 300);
  });

  removeBlankProductGroups();

  removeBlankElements();

  if (productFilterForm.length) {
    const clearAllFilterBtn = document.querySelector('.clear-all-filter');
    if (multiSelectWrapper) {
      choices.passedElement.element.addEventListener('addItem', (event) => {
        event.srcElement.parentNode.classList.add('choices__inner--active');
        // Start Custom Code For Category Filter
        const checkClass = document.getElementsByClassName('filterCat');
        if (checkClass.length > 0) {
          const filterCat = document.querySelector('.filterCat');
          const catFilterList = document.querySelector('.prodcut-category-result-wrap .row');
          const hrefFilterCat = filterCat.getAttribute('action');
          const selectedOptionvalue = event.detail.value;
          document.querySelector('.prodcut-category-result-wrap').classList.add("filter");

          // Set Id from hidden input tag...
          const getHidden = document.getElementsByClassName('multi-select');
          const setValueInHidden = getHidden[0].children[0];

          storeMultipleValue.push(selectedOptionvalue);
          setValueInHidden.value = storeMultipleValue;
          // Get Id from hidden input tag...
          const getLatestHidden = document.getElementsByClassName('multi-select');
          const setLatedtValueInHidden = getLatestHidden[0].children[0];
          // Send form Data....
          const formData = new FormData();
          formData.append('tx_nsproducts_productcategory[paymentmanufactures]', setLatedtValueInHidden.getAttribute('value'));
          sendData(hrefFilterCat, formData, catFilterList);
          // End Custom Code For Category Filter
        }

        // Start Custom Code For Type Filter
        const checkTypeClass = document.getElementsByClassName('filterProType');
        if (checkTypeClass.length > 0) {
          const filterProType = document.querySelector('.filterProType');
          const proTypeFilterList = document.querySelector('.product-groups');
          const hrefFilterProType = filterProType.getAttribute('action');
          const selectedTypeOptionvalue = event.detail.value;
          // Set Id from hidden input tag...
          const getTypeHidden = document.getElementsByClassName('multi-select');
          const setTypeValueInHidden = getTypeHidden[0].children[0];

          storeTypeMultipleValue.push(selectedTypeOptionvalue);
          setTypeValueInHidden.value = storeTypeMultipleValue;
          // Get Id from hidden input tag...
          const getLatestManufacturerTypeHidden = document.getElementsByClassName('multi-select');
          const setLatedtManufacturerTypeValueInHidden = getLatestManufacturerTypeHidden[0].children[0];
          const getLatestCategoryTypeHidden = document.getElementById('productCategoryUidForProType');
          // Send form Data....
          const formFilterTypeData = new FormData();
          formFilterTypeData.append('tx_nsproducts_producttype[productManufacturer]', setLatedtManufacturerTypeValueInHidden.getAttribute('value'));
          formFilterTypeData.append('tx_nsproducts_producttype[productCategoryUid]', getLatestCategoryTypeHidden.getAttribute('value'));
          sendTypeData(hrefFilterProType, formFilterTypeData, proTypeFilterList);
        }
        // End Custom Code For Type Filter

        const searchField = event.srcElement.nextSibling.nextSibling;
        searchField.classList.add('d-none');
        clearAllFilterBtn.classList.add('clear-all-filter--active');
        event.srcElement.parentNode.parentNode.classList.remove('choices--open');
      });
      multiSelectWrapper.addEventListener('change', () => {
        choices.hideDropdown();
      });
      choices.passedElement.element.addEventListener('removeItem', (event) => {
        if (event.target.length === 1) {
          event.srcElement.parentNode.classList.remove('choices__inner--active');
          const searchField = event.srcElement.nextSibling.nextSibling;
          searchField.classList.remove('d-none');
          clearAllFilterBtn.classList.remove('clear-all-filter--active');
  
          // Start Custom Code for Manufacture
          const catFilterListNew = document.querySelector('.prodcut-category-result-wrap .row');
  
          if (catFilterListNew) {
            const allFilerHiddenValueNew = document.querySelector('.allFilter');
            const allListHiddenValueNew = document.querySelector('.selectedManu');
            const listAction = allFilerHiddenValueNew.getAttribute('value');
  
            const getHidden = document.getElementsByClassName('multi-select');
            const setValueInHidden = getHidden[0].children[0];
            setValueInHidden.setAttribute('value', '');
  
            const formDataAll = allListHiddenValueNew.getAttribute('value');
            const variation = 'proManufactureForCat';
            
            removeData(listAction, formDataAll, catFilterListNew, variation);
            // End Custom Code for Manufacture
          }
  
          // Start Custom Code For Product Category
          const proTypeFilterList = document.querySelector('.product-groups');
          if (proTypeFilterList) {
            const allFilterHiddenValueType = document.querySelector('.allFilterType');
            const allListHiddenValueType = document.querySelector('.selectedTypeManu');
            const listActionType = allFilterHiddenValueType.getAttribute('value');
  
            const getTypeHidden = document.getElementsByClassName('multi-select');
            const setTypeValueInHidden = getTypeHidden[0].children[0];
            setTypeValueInHidden.setAttribute('value', '');
            const formDataAllType = allListHiddenValueType.getAttribute('value');
            const variation = 'proCatForType';
            
            removeData(listActionType, formDataAllType, proTypeFilterList, variation);
          }
          // End Custom Code For Product Category
        } else {
          // Start Custom Code For Filter Category
          if (document.querySelector('.filterProType')) {
            const filterProType = document.querySelector('.filterProType');
            const ProTypeFilterList = document.querySelector('.product-groups');
            const hrefFilterProType = filterProType.getAttribute('action');
            const selectedProTypeOptionvalue = event.detail.value;
            // Get hidden input value
            const getHiddenProType = document.getElementsByClassName('multi-select');
            const setproTypeValueInHidden = getHiddenProType[0].children[0];
            const getproTypeHiddenvalue = setproTypeValueInHidden.getAttribute('value');
            const arrayProType = getproTypeHiddenvalue.split(',');
            const index = arrayProType.indexOf(selectedProTypeOptionvalue);
            if (index > -1) { // only splice array when item is found
              arrayProType.splice(index, 1); // 2nd parameter means remove one item only
              setproTypeValueInHidden.value = arrayProType;
            }
            const variation = 'proCatForType';
            const proTypeformData = new FormData();
            proTypeformData.append('tx_nsproducts_producttype[filterManuFacUid]', setproTypeValueInHidden.getAttribute('value'));
            proTypeformData.append('tx_nsproducts_producttype[productCategoryUidForProType]', document.getElementById('productCategoryUidForProType').getAttribute('value'));
            removeData(hrefFilterProType, proTypeformData, ProTypeFilterList, variation);
          }
          // End Custom Code For Filter Category
          // Start Custom Code For Filter Product Type
          if (document.querySelector('.filterCat')) {
            const filterCat = document.querySelector('.filterCat');
            const catFilterList = document.querySelector('.prodcut-category-result-wrap .row');
            const hrefFilterCat = filterCat.getAttribute('action');
            const selectedOptionvalue = event.detail.value; // Get current selected option value
            // Get hidden input value
            const getHidden = document.getElementsByClassName('multi-select');
            const setValueInHidden = getHidden[0].children[0];
            const getHiddenvalue = setValueInHidden.getAttribute('value');
            const array = getHiddenvalue.split(',');
            const index = array.indexOf(selectedOptionvalue);
            if (index > -1) { // only splice array when item is found
              array.splice(index, 1); // 2nd parameter means remove one item only
              setValueInHidden.value = array;
            }
            const variation = 'proManufactureForCat';
            const formData = new FormData();
            formData.append('tx_nsproducts_productcategory[paymentmanufactures]', setValueInHidden.getAttribute('value'));
            sendData(hrefFilterCat, formData, catFilterList);
          }
        }

        // End Custom Code For Filter Product Type
      });
  
      multiSelectWrapper.addEventListener('addItem', ($e) => {
        const elChild = document.createElement('li');
        const textnode = document.createTextNode($e.detail.value);
        elChild.setAttribute('data-value', $e.detail.value);
        elChild.appendChild(textnode);
        elChild.innerHTML = $e.detail.label;
        tagWrapper.appendChild(elChild);
        const tagLists = tagWrapper.querySelectorAll('li');
        tagLists.forEach(($ele) => {
          $ele.addEventListener('click', () => {
            const dataValue = $ele.getAttribute('data-value');
            const choiceList = document.querySelectorAll('.multi-select .choices__list--multiple .choices__item');
            const selectList = document.querySelectorAll('.multi-select .form-select option');
            if (choiceList.length) {
              const choliceLength = choiceList.length;
              for (let i = 0; i < choliceLength; i += 1) {
                const choiceValue = choiceList[i].getAttribute('data-value');
                const selectValue = selectList[i].getAttribute('value');
                if (dataValue === choiceValue) {
                  setTimeout(() => {
                    choices.removeActiveItemsByValue(selectValue);
                  }, 500);
                }
              }
            }
            $ele.remove();
          });
        });
      });
  
      clearAllFilterBtn.addEventListener('click', (e) => {
        const tagLists = tagWrapper.querySelectorAll('li');
        tagLists.forEach(($ele) => {
          const dataValue = $ele.getAttribute('data-value');
          const choiceList = document.querySelectorAll('.multi-select .choices__list--multiple .choices__item');
          const selectList = document.querySelectorAll('.multi-select .form-select option');
          if (choiceList.length) {
            const choliceLength = choiceList.length;
            for (let i = 0; i < choliceLength; i += 1) {
              const choiceValue = choiceList[i].getAttribute('data-value');
              const selectValue = selectList[i].getAttribute('value');
              if (dataValue === choiceValue) {
                setTimeout(() => {
                  choices.removeActiveItemsByValue(selectValue);
                }, 500);
              }
            }
          }
          $ele.remove();
          clearAllFilterBtn.classList.remove('clear-all-filter--active');
          // Start Custom Code for Manufacture
          const catFilterListNew = document.querySelector('.prodcut-category-result-wrap .row');

          if (catFilterListNew) {
            const allFilerHiddenValueNew = document.querySelector('.allFilter');
            const allListHiddenValueNew = document.querySelector('.selectedManu');
            const listAction = allFilerHiddenValueNew.getAttribute('value');
            const getHidden = document.getElementsByClassName('multi-select');
            const setValueInHidden = getHidden[0].children[0];
            setValueInHidden.setAttribute('value', '');
            storeMultipleValue = [];
            const formDataAll = allListHiddenValueNew.getAttribute('value');
            const variation = 'proManufactureForCat';
            removeData(listAction, formDataAll, catFilterListNew, variation);
            // End Custom Code for Manufacture
          }

          // Start Custom Code For Product Category
          const proTypeFilterList = document.querySelector('.product-groups');
  
          if (proTypeFilterList) {
            const allListHiddenValueType = document.querySelector('.selectedTypeManu');
            const listActionType = document.querySelector('.backProTypeUrl').innerHTML;
            const getTypeHidden = document.getElementsByClassName('multi-select');
            const setTypeValueInHidden = getTypeHidden[0].children[0];
            setTypeValueInHidden.setAttribute('value', '');
            storeTypeMultipleValue = [];
            const formDataAllType = allListHiddenValueType.getAttribute('value');
            const variation = 'proCatForType';
            removeData(listActionType, formDataAllType, proTypeFilterList, variation);
          }
          // End Custom Code For Product Category
        });
      });
    }
  }

  function sendData(hrefFilterCat, formData, catFilterList) {
    fetch(hrefFilterCat, {
      method: 'POST',
      body: formData,
    }).then((resp) => { return resp.text(); }).then((html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      let displayData;
      displayData = doc.querySelector('.prodcut-category-result-wrap .row').innerHTML;
      catFilterList.innerHTML = displayData;
      const ll = new LazyLoad({
        class_applied: 'lz-applied',
        class_loading: 'lz-loading',
        class_loaded: 'lz-loaded',
        class_error: 'lz-error',
        class_entered: 'lz-entered',
        class_exited: 'lz-exited',
      });
    }).catch(() => {});
  }
  
  function removeData(listAction, formDataAll, catFilterListNew, variation) {
    fetch(listAction, {
      method: 'POST',
      body: formDataAll,
    }).then((resp) => { return resp.text(); }).then((html) => {
      
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      let displayDataNew;
      if (variation == 'proManufactureForCat') {
        displayDataNew = doc.querySelector('.prodcut-category-result-wrap .row').innerHTML;
      }
      else {
        displayDataNew = doc.querySelector('.product-groups').innerHTML;
      }
      catFilterListNew.innerHTML = displayDataNew;
      removeBlankElements();
      const ll = new LazyLoad({
        class_applied: 'lz-applied',
        class_loading: 'lz-loading',
        class_loaded: 'lz-loaded',
        class_error: 'lz-error',
        class_entered: 'lz-entered',
        class_exited: 'lz-exited',
      });
    }).catch(() => {});
  }
  
  function sendTypeData(hrefFilterProType, formFilterTypeData, proTypeFilterList) {
    
    fetch(hrefFilterProType, {
      method: 'POST',
      body: formFilterTypeData,
      timeout: 5000,
    }).then((resp) => { return resp.text(); }).then((html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
  
      const displayFilterGroupData = doc.querySelector('.product-groups').innerHTML;
      proTypeFilterList.innerHTML = displayFilterGroupData;
      //proTypeFilterList.innerHTML.append(displayFilterGroupData);
      
      removeBlankElements();
      removeBlankProductGroups();
      const ll = new LazyLoad({
        class_applied: 'lz-applied',
        class_loading: 'lz-loading',
        class_loaded: 'lz-loaded',
        class_error: 'lz-error',
        class_entered: 'lz-entered',
        class_exited: 'lz-exited',
      });
    }).catch(() => {});
  }
  
  // Remove Blank Elemennts From Product Type
  function removeBlankElements() {
    const emptyElement = document.querySelectorAll('.product-group__type-col');
    if (emptyElement) {
      emptyElement.forEach(($ele) => {
        if ($ele.childNodes.length <= 1) {
          $ele.remove();
        }
      });
    }
  }

  // Remove Blank Groups
  function removeBlankProductGroups() {
    const emptyGpElement = document.querySelectorAll('.product-groups .product-group');
    if(emptyGpElement) {
      emptyGpElement.forEach(($ele) => {
        const productGptype = $ele.querySelectorAll('.product-type-teaser');
        if (productGptype.length <= 0) {
          $ele.remove();
        }
      });
    }
  }

}
//
// Product CTA
//

// ScrollMagic
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const productCTABlock = document.querySelectorAll('.product-cta');

if (productCTABlock) {
  if (window.innerWidth > 1199) {
    productCTABlock.forEach(($ctaBlock) => {
      const $ctaBlockSection = $ctaBlock.closest('.product-cta-section');
      const $ctaLastBlck = $ctaBlock.querySelector('.product-cta__block--last');
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: $ctaBlockSection,
          scrub: true,
          toggleActions: 'play complete reverse reverse',
          start: '0% bottom',
          end: '+=100% 40%',
        },
      });
      tl.add(() => {
        $ctaLastBlck.classList.remove('product-cta__block--last-animate');
      }).to($ctaLastBlck, {
        duration: 1,
      }).add(() => {
        $ctaLastBlck.classList.add('product-cta__block--last-animate');
      });
    });
  }
}

if (!document.body.className.includes('ns-website-content')) {
  document.addEventListener('click', (event) => {
    if (event.target.classList.contains('services-wrapper')) {
      const serviceItem = event.target.querySelector('.services-item--active');
      serviceItem.click();
    }
  });
}

const serviceItem = document.querySelectorAll('.services-item');
if (serviceItem.length) {
  serviceItem.forEach((e, i, array) => {
    e.addEventListener('click', (event) => {
      if (i === array.length - 1 && e.classList.contains('services-item--active')) {
        event.target.classList.remove('services-item--active');
        document.querySelector('.services-item:first-child').classList.add('services-item--active');
      } else if (e.classList.contains('services-item--active')) {
        event.target.classList.remove('services-item--active');
        event.target.nextElementSibling.classList.add('services-item--active');
      }
    });
  });
}

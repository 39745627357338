//
// Scroll To Top 
//
const gotoTop = document.querySelector('.goto-top');

if (gotoTop) {
  window.addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > 100) {
      gotoTop.classList.add('goto-top--visible');
    } else {
      gotoTop.classList.remove('goto-top--visible');
    }
  });

  gotoTop.addEventListener('click', (event) => {
    event.preventDefault();
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });
}

const href = window.location.hash;
if (href) {
  const elem = document.querySelector(href);
  if (elem) {
    window.scroll({
      top: elem.offsetTop - 65,
      left: 0,
      behavior: 'smooth',
    });

    setTimeout(() => {
      window.scroll({
        top: elem.offsetTop - 65,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }
}

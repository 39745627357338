//
// Products
//

const manufacturerBtns = document.querySelectorAll('.manufacturer-lists--product-type .manufacturer-teaser .btn');

if (manufacturerBtns) {
  manufacturerBtns.forEach(($btn) => {
    $btn.addEventListener('click', (e) => {
      e.preventDefault();
      const headerHeight = document.querySelector('.page-header').clientHeight;
      const formSection = document.querySelector('.form-section');
      let formSectionTopOffset = '';

      if (formSection) {
        formSectionTopOffset = formSection.offsetTop - headerHeight;
        document.documentElement.scrollTo({
          top: formSectionTopOffset,
          behavior: 'smooth',
        });
      }
    });
  });
}

//
// Hero
//

// ScrollMagic
import Flickity from 'flickity';
import gsap from 'gsap';

// get other plugins:
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const heroMediumImg = document.querySelectorAll('.hero-section__medium-inner .hero-section__img');
let heroSection = '';

const heroNewsSlider = document.querySelectorAll('.hero-news-slider');
if (heroNewsSlider) {
  const heroNewsTrigger = document.querySelectorAll('.hero-section__news-trigger');

  heroNewsSlider.forEach(($ele) => {
    const slides = $ele.querySelectorAll('.hero-news-slide');
    if (slides.length > 1) {
      const flkty = new Flickity($ele, {
        prevNextButtons: false,
        cellSelector: '.hero-news-slide',
        cellAlign: 'left',
        pageDots: true,
        freeScroll: false,
        contain: true,
        resize: true,
      });
    }
  });

  if (heroNewsTrigger) {
    heroNewsTrigger.forEach(($trigger) => {
      $trigger.addEventListener('click', () => {
        if (window.innerWidth > 1199) {
          $trigger.closest('.hero-section__news').classList.toggle('hero-section__news--open');
        }
      });
    });
  }
}

if (heroMediumImg) {
  if (window.innerWidth > 1199) {
    heroMediumImg.forEach(($mImg) => {
      heroSection = $mImg.closest('.hero-section--medium');
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: heroSection,
          scrub: true,
          toggleActions: 'play complete reverse reverse',
          start: '7% top',
          end: '+=10px 0%',
        },
      });

      tl.add(() => {
        $mImg.classList.remove('hero-section__img--animate');
      }).to($mImg, {
        duration: 10,
      }).add(() => {
        $mImg.classList.add('hero-section__img--animate');
      });
    });
  }
}

import LazyLoad from 'vanilla-lazyload';

const searchBox = document.querySelector('.search-cate-wrap input');
const searchCat = document.querySelector('.searchCat');
const catList = document.querySelector('.prodcut-category-result-wrap .row');
if (searchBox) {
  searchBox.addEventListener('keyup', (e) => {
    e.preventDefault();
    setTimeout(() => {
        searchCategoryFunction();
    }, 300);
  });

  function searchCategoryFunction() {
    if(document.getElementById('search-cate')) {
      const catValue = document.getElementById('search-cate').value;
      const allFilerHiddenValueNew = document.querySelector('.allFilter');
      let href;
      if (catValue.length > 0) {
        href = searchCat.getAttribute('action');
      } else {
        href = allFilerHiddenValueNew.getAttribute('value');
      }

      const formData = new FormData();
      formData.append('tx_nsproducts_productcategory[category]', catValue);

      catList.innerHTML = '';
      fetch(href, {
        method: 'POST',
        body: formData,
      }).then((resp) => { return resp.text(); }).then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const displayData = doc.querySelector('.prodcut-category-result-wrap .row').innerHTML;
        catList.innerHTML = displayData;
        const ll = new LazyLoad({
          class_applied: 'lz-applied',
          class_loading: 'lz-loading',
          class_loaded: 'lz-loaded',
          class_error: 'lz-error',
          class_entered: 'lz-entered',
          class_exited: 'lz-exited',
        });
      }).catch(() => {});
    }  
  }
}

// End Category Search.......

// Start Product Type Search......

const searchTypeBox = document.querySelector('.search-cate-wrap input');
const searchProType = document.querySelector('.searchProType');
const proTypeList = document.querySelector('.product-groups');

if (searchTypeBox) {
    searchTypeBox.addEventListener('keyup', (e) => {
        e.preventDefault();
        setTimeout(() => {
            searchTypeFunction();
        }, 1000);
    });

    searchBox.addEventListener('keydown', (event) => {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    });

    function searchTypeFunction(){
        const proTypeValue = document.getElementById('search-type').value;
        const allFilerHiddenValueNew = document.querySelector('.allFilter');
        let hrefType;
        hrefType = searchProType.getAttribute('action');
        const formTypeData = new FormData();
        formTypeData.append('tx_nsproducts_producttype[proType]', proTypeValue);
        proTypeList.innerHTML = '';
        fetch(hrefType, {
            method: 'POST',
            body: formTypeData,
        }).then((resp) => { return resp.text(); }).then((html) => {

            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const displayGroupData = doc.querySelector('.product-groups').innerHTML;    
            proTypeList.innerHTML = displayGroupData;
            // Remove Blank Elemennts From Product Type
            const emptyElement = document.querySelectorAll('.product-group__type-col');
            if (emptyElement) {
                emptyElement.forEach(($ele) => {
                    if ($ele.childNodes.length <= 1) {
                        $ele.remove();
                    }
                });
            }
            
            const ll = new LazyLoad({
                class_applied: 'lz-applied',
                class_loading: 'lz-loading',
                class_loaded: 'lz-loaded',
                class_error: 'lz-error',
                class_entered: 'lz-entered',
                class_exited: 'lz-exited',
            });
        }).catch(() => {});
    }
}

//
// Dropzone
//

import {Dropzone} from 'dropzone';

const form = document.querySelector('.mutistep-form');
let url = '';
if (document.querySelector('.mutistep-form')) {
  url = form.getAttribute('action');
}
let dropzone = {};

if (document.querySelectorAll('.demo-upload').length) {
    dropzone = new Dropzone('.demo-upload', {
        url,
        paramName: 'files',
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        dictRemoveFile: '',
        dictCancelUpload: '',
        acceptedFiles: '.jpeg, .jpg, .png, .gif, .pdf, .mp4, .zip, .mov, .wmv',
        previewTemplate: document.querySelector('#preview-template').innerHTML,
        parallelUploads: 3,
        thumbnailHeight: 50,
        dictDefaultMessage: 'Ziehen Sie eine oder mehrere Dateien auf diese Fläche oder klicken Sie hier, um eine Datei auszuwählen (erlaubt sind ZIP, PDF, File Schema, Fotos, Videos)',
        thumbnailWidth: 50,
        maxFilesize: 50,
        filesizeBase: 100000000000,
        maxFiles: 10,
        init() {
            //this function will call if dropzone not submit with attached file...

            form.addEventListener('submit', handleFormSubmit);

            this.on('addedfile', (file) => {
                if (file.type.includes('image')) {
                    file.previewElement.classList.add('image__uploading');
                } else if (file.type.includes('video')) {
                    file.previewElement.classList.add('video__uploading');
                } else {
                    file.previewElement.classList.add('file__uploading');
                }
            });
            this.on('error', function (file, message) {
                console.log(message);
                this.removeFile(file);
            });
            this.on('sendingmultiple', (data, xhr, formData) => {
                const formValues = new FormData(form);
                for (const [key, value] of formValues) {
                    formData.append(key, value);
                }
                if( document.querySelector('.form-wrap form')){
                    const multiFormImgUpload = document.querySelector('.form-wrap form').getAttribute('id');
                    formData.append('tx_form_formframework[' + multiFormImgUpload + '][__currentPage]', '1');
                }    
            });
            this.on('complete', (file) => {
                if (file.status != 'error') {
                   // redirect to success page....
                }
            });
        },
        success(file) {
            file.upload = {
                progress: 0,
                total: file.size,
                bytesSent: 0,
            };
            this.files.push(file);
            file.status = Dropzone.ADDED;
            file.accepted = true;

            const previewEl = file.previewElement;
            previewEl.classList.add('image__open');
            if (file.type.includes('image')) {
                file.previewElement.classList.remove('image__uploading');
                file.previewElement.classList.add('image__uploaded');
            } else if (file.type.includes('video')) {
                file.previewElement.classList.remove('video__uploading');
                file.previewElement.classList.add('video__uploaded');
            } else {
                file.previewElement.classList.remove('file__uploading');
                file.previewElement.classList.add('file__uploaded');
            }
        },
        thumbnail(file, dataUrl) {
            if (file.previewElement) {
                file.previewElement.classList.remove('dz-file-preview');
                const images = file.previewElement.querySelectorAll(
                    '[data-dz-thumbnail]',
                );
                for (let i = 0; i < images.length; i++) {
                    const thumbnailElement = images[i];
                    thumbnailElement.alt = file.name;
                    thumbnailElement.src = dataUrl;
                }
                setTimeout(() => {
                    file.previewElement.classList.add('dz-image-preview');
                }, 800);
            }
        },
    });
    dropzone.filesize = function (bytes) {
        let selectedSize = 0;
        const units = ['kb', 'mb', 'gb', 'tb'];

        if (Math.abs(bytes) < this.options.filesizeBase) {
            selectedSize = bytes;
        } else {
            let u = -1;
            do {
                bytes /= this.options.filesizeBase;
                ++u;
            } while (
                Math.abs(bytes) >= this.options.filesizeBase
                && u < units.length - 1
                );

            selectedSize = bytes.toFixed(1);
            selectedUnit = units[u];
            console.log(files);
        }

        if (selectedSize >= 1073741824) {
            selectedSize = `${(selectedSize / 1073741824).toFixed(2)} GB`;
        } else if (selectedSize >= 1048576) {
            selectedSize = `${(selectedSize / 1048576).toFixed(2)} MB`;
        } else if (selectedSize >= 1024) {
            selectedSize = `${(selectedSize / 1024).toFixed(2)} KB`;
        } else if (selectedSize > 1) {
            selectedSize += ' B';
        } else if (selectedSize == 1) {
            selectedSize += ' byte';
        } else {
            selectedSize = '0 selectedSize';
        }
        return `${selectedSize}`;
    };
}

window.addEventListener("DOMContentLoaded", (event) => {
    if (document.querySelector('.form-wrap form')) {
        const multiForm = document.querySelector('.form-wrap form').getAttribute('id');

        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        const insertAfter = (el, htmlString) => el.insertAdjacentHTML('afterend', htmlString);
        document.querySelector('#' + multiForm + '-fieldset-1').style.display = 'none';
        document.querySelector('#previousButton').style.display = 'none';
        document.querySelector('#submitButton').style.display = 'none';
        document.getElementById('nextPageBtn').addEventListener('click', function(event) {

            if(document.getElementById('productForm-65-textarea-1') && document.getElementById('productForm-65-textarea-1').value === ''){
                let nextSibling = document.getElementById('productForm-65-textarea-1');

                const shownError = document.getElementById(multiForm + '-textarea-1');
                
                if (!checkErrorDiv(nextSibling)) {
                    insertAfter(shownError, '<span class="error help-block" role="alert">Bitte geben Sie Informationen zum Produkt an</span>');
                }
                addErrorMessage(shownError);
                return;
            }
            if(document.getElementById('productForm-110-text-6') && document.getElementById('productForm-110-text-6').value === ''){
                let nextSibling = document.getElementById('productForm-110-text-6');

                const shownError = document.getElementById(multiForm + '-text-6');
                if (!checkErrorDiv(nextSibling)) {
                    insertAfter(shownError, '<span class="error help-block" role="alert">Bitte geben Sie Informationen zum Produkt an</span>');
                }    
                addErrorMessage(shownError);
                return;
            }

            document.querySelector('#' + multiForm + '-fieldset-2').style.display = 'none';
            document.querySelector('#nextButton').style.display = 'none';
            document.querySelector('#previousButton').style.display = 'block';
            document.querySelector('#submitButton').style.display = 'block';
            document.querySelector('#' + multiForm + '-fieldset-1').style.display = 'block';
        });
        document.getElementById('previousPageBtn').addEventListener('click', function(event) {
            document.querySelector('#' + multiForm + '-fieldset-2').style.display = 'block';
            document.querySelector('#nextButton').style.display = 'block';
            document.querySelector('#previousButton').style.display = 'none';
            document.querySelector('#submitButton').style.display = 'none';
            document.querySelector('#' + multiForm + '-fieldset-1').style.display = 'none';
        });

        document.getElementById('submit').addEventListener('click', function(event) {
            if(document.getElementById(multiForm + '-text-1')){
                if(document.getElementById(multiForm + '-text-1').value === ''){
                    const shownError = document.getElementById(multiForm + '-text-1');
                    if (!checkErrorDiv(shownError)) {
                        insertAfter(shownError, '<span class="error help-block" role="alert">Bitte geben Sie Ihren Vornamen an</span>');
                    }
                    addErrorMessage(shownError);
                    //return;
                } else {
                    const shownErrorRemove = document.getElementById(multiForm + '-text-1');
                    removeErrorClass(shownErrorRemove);
                }
            }
            if(document.getElementById(multiForm + '-text-2')){
                if(document.getElementById(multiForm + '-text-2').value === ''){
                    const shownError = document.getElementById(multiForm + '-text-2');
                    if (!checkErrorDiv(shownError)) {
                        insertAfter(shownError, '<span class="error help-block" role="alert">Bitte geben Sie Ihren Nachnamen an</span>');
                    }
                    addErrorMessage(shownError);
                    //return;
                }
                else {
                    const shownErrorRemove = document.getElementById(multiForm + '-text-2');
                    removeErrorClass(shownErrorRemove);
                }
            } 
            if(document.getElementById(multiForm + '-email-1')){
                const shownError = document.getElementById(multiForm + '-email-1');
                shownError.setAttribute("type", "text");
                if(document.getElementById(multiForm + '-email-1').value === ''){
                    if (!checkErrorDiv(shownError)) {
                        insertAfter(shownError, '<span class="error help-block" role="alert">Sie müssen eine gültige Email-Adresse angeben</span>');
                    }
                    addErrorMessage(shownError);
                    //return;
                }
                // else if(!shownError.value.match(mailformat)) {
                //     if (checkErrorDiv(shownError)) {
                //         insertAfter(shownError, '<span class="error help-block" role="alert">Sie müssen eine gültige Email-Adresse angeben</span>');
                //     }
                //     addErrorMessage(shownError);
                //     //return;
                // }
                else {
                    const shownErrorRemove = document.getElementById(multiForm + '-email-1');
                    removeErrorClass(shownErrorRemove);
                }
            } 

            if(document.getElementById(multiForm + '-text-5')) {
                if(document.getElementById(multiForm + '-text-5').value === ''){
                    const shownError = document.getElementById(multiForm + '-text-5');
                    if (!checkErrorDiv(shownError)) {
                        insertAfter(shownError, '<span class="error help-block" role="alert">Bitte geben Sie Ihren Vornamen an</span>');
                    }
                    addErrorMessage(shownError);
                    //return;
                }
                else {
                    const shownErrorRemove = document.getElementById(multiForm + '-text-5');
                    removeErrorClass(shownErrorRemove);
                }
            } 
            if(document.getElementById(multiForm + '-text-7')) {
                if(document.getElementById(multiForm + '-text-7').value === ''){
                    const shownError = document.getElementById(multiForm + '-text-7');
                    if (!checkErrorDiv(shownError)) {
                        insertAfter(shownError, '<span class="error help-block" role="alert">Bitte geben Sie Ihren Nachnamen an</span>');
                    }
                    addErrorMessage(shownError);
                    //return;
                }
                else {
                    const shownErrorRemove = document.getElementById(multiForm + '-text-7');
                    removeErrorClass(shownErrorRemove);
                }
            } 
            if(document.getElementById(multiForm + '-email-2')) {
                const shownError = document.getElementById(multiForm + '-email-2');
                shownError.setAttribute("type", "text");
                if(document.getElementById(multiForm + '-email-2').value === ''){
                    
                    if (!checkErrorDiv(shownError)) {
                        insertAfter(shownError, '<span class="error help-block" role="alert">Sie müssen eine gültige Email-Adresse angeben</span>');
                    }
                    addErrorMessage(shownError);
                    //return;
                }
                // else if(!shownError.value.match(mailformat)) {
                //     if (checkErrorDiv(shownError)) {
                //         insertAfter(shownError, '<span class="error help-block" role="alert">Sie müssen eine gültige Email-Adresse angeben</span>');
                //     }
                //     addErrorMessage(shownError);
                //     //return;
                // }
                else {
                    const shownErrorRemove = document.getElementById(multiForm + '-email-2');
                    removeErrorClass(shownErrorRemove);
                }
            } 
            form.addEventListener('submit', handleFormSubmit);
        });
    }
});

function addErrorMessage(shownError) {
  shownError.className = 'error';
  const subParent = shownError.parentElement;
  subParent.parentElement.classList.add('has-error');
}

function removeErrorClass(shownErrorRemove) {
  const nextElement = shownErrorRemove.nextSibling;
  if (nextElement) {
    nextElement.parentNode.removeChild(nextElement);
  }
  shownErrorRemove.classList.remove("error");
  const subParentRemove = shownErrorRemove.parentElement;
  subParentRemove.parentElement.classList.remove('has-error');
}

function checkErrorDiv(nextSibling) {
    let checkErrorClass;

    let errorTag = nextSibling.nextElementSibling;

    while (errorTag) {
      if (errorTag.classList.contains('help-block')) {
        checkErrorClass = errorTag;
        break;
      }

      errorTag = errorTag.nextElementSibling;
    }
    return checkErrorClass;
}

async function handleFormSubmit(event) {
  event.preventDefault();
  const form = event.currentTarget;
  const url = form.action;

  try {
    const formData = new FormData(form);
    if (form.checkValidity()) {
      if (dropzone.getQueuedFiles().length > 0) {
        dropzone.processQueue();
        setTimeout(() => {
          window.location = "https://www.donne-hydraulik.de/vielen-dank";
        }, '1000');
      } else {
        const responseData = await postFormDataAsJson({ url, formData });
        if (responseData.ok) {
            setTimeout(() => {
              window.location = "https://www.donne-hydraulik.de/vielen-dank";
            }, '1000');
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

async function postFormDataAsJson({ url, formData }) {
  if (document.querySelector('.form-wrap form')) {
    const multiFormCheck = document.querySelector('.form-wrap form').getAttribute('id');
    formData.append('tx_form_formframework[' + multiFormCheck + '][__currentPage]', '1');
    const fetchOptions = {
      method: 'POST',
      body: formData,
    };

    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    }

    return response;
  }
}

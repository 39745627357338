//
// Image Slider
//
import Flickity from 'flickity';

const heroImageSlider = document.querySelectorAll('.img-slider');

let fadeVal = true;
let prevNextArrow = false;
let slideLoop = false;
let slideAlign = 'left';
let autoplay = true;

if (heroImageSlider) {
  heroImageSlider.forEach(($ele) => {
    const slides = $ele.querySelectorAll('.img-slide');

    if ($ele.closest('.product-type-intro-section')) {
      prevNextArrow = true;
      slideAlign = 'center';
      slideLoop = true;
      autoplay = false;
      if (window.innerWidth < 767) {
        fadeVal = false;
      }
    }

    if (slides.length > 1) {
      const flkty = new Flickity($ele, {
        prevNextButtons: prevNextArrow,
        cellSelector: '.img-slide',
        cellAlign: slideAlign,
        pageDots: false,
        freeScroll: slideLoop,
        wrapAround: slideLoop,
        draggable: true,
        contain: true,
        resize: true,
        lazyLoad: 3,
        fade: fadeVal,
        autoPlay: autoplay,
      });
    }
  });
}

// using ES6 modules
import VanillaSiteSpeedBooster from '@nitsantechnologies/vanilla-site-speed-booster';

// using CommonJS modules
const booster = new VanillaSiteSpeedBooster({
  enableProgressBar: true,
  idBundleJs: 'pageAjax',
  errorMsg: 'Oops! Fatal error in VanillaSiteSpeedBooster plugin',
  mainClassName: '.site-main',
  pageBackForwardReload: true,
  headerCollapse: false,
  removeWithoutReloadUsingTargetClass: ['exclude-speed', 'accordion-button'],
  removeUsingTargetClass: ['accordion-button', 'category-teaser', 'manufacturers-mainlink', 'product-type-teaser', 'search-page'],
});

//
// Line Animation
//

// ScrollMagic
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const headlineLine = document.querySelectorAll('h1.line, h2.line');

if (headlineLine) {
  headlineLine.forEach(($line) => {
    const spanEle = document.createElement('span');
    $line.prepend(spanEle);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: $line,
        scrub: true,
        toggleActions: 'play complete reverse reverse',
        start: '0% 80%',
        end: '+=10px 80%',
      },
    });
    tl.add(() => {
      $line.classList.remove('line-animate');
    }).to($line, {
      duration: 1,
    }).add(() => {
      $line.classList.add('line-animate');
    });
    const heroLine = document.querySelector('.hero-section__content h1.line, .hero-section__content h2.line, .product-type-intro-section h2.line');
    if (heroLine) {
      window.addEventListener('scroll', () => {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st < 1) {
          heroLine.classList.remove('line-animate');
        } else {
          heroLine.classList.add('line-animate');
        }
      });
    }
  });
}

//
// theme.js
//

// Vendor
import 'flickity';
import 'flickity-fade';

// Theme
import './aos';
import './header';
import './lazyload';
import './scrollToTop';
import './hero';
import './imageSlider';
import './product';
import './line-animate';
import './product-category-filter';
import './services';
import './contact-form';
import './map';
import './speed-booster';
import './dropzone';
import './product-cta';
import './product-filter';

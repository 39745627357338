//
// Header
//

export default class PageHeader {
  constructor($ele) {
    this.$body = document.body;
    this.$pageHeader = $ele;
    this.$menuTrigger = $ele.querySelector('.menu-trigger');
    this.$menuCloseTrigger = $ele.querySelector('.menu-close-trigger');
    this.$chatTrigger = $ele.querySelector('.header-main__chat-trigger');
    this.$newsTrigger = $ele.querySelector('.header-main__news-trigger');
    this.$newsCloseTrigger = document.querySelector('.news-close-trigger');
    this.$heroNews = document.querySelector('.hero-section__news');

    this.menuTrigger();
    this.menuCloseTrigger();
    if (this.$heroNews) {
      this.newsTrigger();
      this.newsCloseTrigger();
    }
    this.chatTrigger();
    this.onScroll();
    this.onResize();
  }

  onResize() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 1199) {
        if (this.$body.classList.contains('menu--open')) {
          this.$body.classList.remove('menu--open');
        }

        if (this.$body.classList.contains('news--open')) {
          this.$body.classList.remove('news--open');
        }
      }
    });
  }

  onScroll() {
    window.addEventListener('scroll', () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > 100) {
        this.$pageHeader.classList.add('header--sticky');
      } else {
        this.$pageHeader.classList.remove('header--sticky');
      }
    });
  }

  menuTrigger() {
    this.$menuTrigger.addEventListener('click', () => {
      this.$body.classList.remove('news--open');
      this.$body.classList.toggle('menu--open');
    });
  }

  menuCloseTrigger() {
    this.$menuCloseTrigger.addEventListener('click', () => {
      this.$body.classList.remove('menu--open');
    });
  }

  newsTrigger() {
    this.$newsTrigger.addEventListener('click', () => {
      this.$body.classList.remove('menu--open');
      this.$body.classList.toggle('news--open');
    });
  }

  newsCloseTrigger() {
    this.$newsCloseTrigger.addEventListener('click', () => {
      this.$body.classList.remove('news--open');
    });
  }

  chatTrigger() {
    this.$chatTrigger.addEventListener('click', () => {
      this.$body.classList.remove('menu--open');
      this.$body.classList.remove('news--open');
      this.$body.classList.toggle('chat--open');
    });
  }
}

if (!document.body.className.includes('ns-website-content')) {
  document.querySelectorAll('.navigation .nav-item a').forEach(($item) => {
    $item.addEventListener('click', () => {
      if (window.innerWidth < 1200) {
        document.body.classList.remove('menu--open');
      }
    });
  });

  document.querySelector('.navigation nav .btn').addEventListener('click', () => {
    if (window.innerWidth < 1200) {
      document.body.classList.remove('menu--open');
    }
  });

  document.querySelectorAll('.page-header').forEach(($el) => new PageHeader($el));
}
